/* Sidebar */

.sidebar {
  min-height: calc(100vh - #{$navbar-height});
  background: var(--bg-leftside);
  font-weight: normal;
  padding: 0;
  width: $sidebar-width-lg;
  z-index: 11;
  transition: width $action-transition-duration
      $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration
      $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration
      $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration
      $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;

  .sidebar-brand-wrapper {
    transition: width $action-transition-duration
        $action-transition-timing-function,
      background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration
        $action-transition-timing-function,
      background $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration
        $action-transition-timing-function,
      background $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration
        $action-transition-timing-function,
      background $action-transition-duration $action-transition-timing-function;
    background: $sidebar-bg;
    width: $sidebar-width-lg;
    height: $navbar-height;
    @media (max-width: 991px) {
      width: 55px;
    }
    .sidebar-brand {
      color: lighten(color(gray-dark), 20%);
      font-size: 1.5rem;
      line-height: 48px;
      margin-right: 0;
      padding: 0;
      width: 100%;
      display: flex;

      &:active,
      &:focus,
      &:hover {
        color: lighten(color(gray-dark), 10%);
      }

      img {
        width: 53%;
        min-height: auto;
        object-fit: cover;
        object-position: center;
        margin: auto;
        vertical-align: middle;
        padding-top: 8px;
      }
      &.brand-logo-mini {
        display: none;
        img {
          width: 100%;
          min-height: 28px;
          object-fit: cover;
          object-position: center;
          margin: auto;
        }
      }
    }
  }

  .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 60px;
    padding-top: $navbar-height;
    @media (max-width: 992px) {
      padding-top: 0;
    }

    .nav-item {
      @include transition-duration(0.25s);
      transition-property: background;
      -webkit-transition-property: background;
      padding-right: 20px;
      .collapse {
        z-index: 999;
      }

      .nav-link {
        @include display-flex;
        @include align-items(center);
        white-space: nowrap;
        padding: $sidebar-menu-padding-y 10px $sidebar-menu-padding-y
          $sidebar-menu-padding-x;
        color: var(--textColor);
        @include transition-duration(0.45s);
        transition-property: color;
        -webkit-transition-property: color;
        height: 46px;
        border-radius: 0px 100px 100px 0px;
        cursor: pointer;

        i {
          color: inherit;
          &.menu-icon {
            font-size: $sidebar-icon-font-size;
            line-height: 1;
            margin-left: auto;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
            color: $sidebar-menu-icon-color;
            &:before {
              vertical-align: middle;
            }
          }
          &.menu-arrow {
            font: normal normal normal 24px/1 "Material Design Icons";
            line-height: 1;
            font-size: $sidebar-icon-font-size;
            margin-left: auto;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
            color: $sidebar-menu-arrow-color;
            &:before {
              content: "\f140";
              font-size: inherit;
              color: inherit;
            }
          }
        }
        .menu-title {
          color: inherit;
          display: inline-block;
          font-size: 15px;
          line-height: 1;
          vertical-align: middle;
        }
        .badge {
          margin-right: auto;
          margin-left: 1rem;
        }
      }

      &.active {
        > .nav-link {
          background: var(--bg-activeleftsidebar);
          position: relative;
          &:before {
            content: "";
            width: 3px;
            height: 100%;
            background: #0090e7;
            // background: #0090e7;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
          }
          .menu-title {
            color: $sidebar-menu-active-color;
          }
          i {
            color: #ffffff !important;
          }
        }
      }
      &.nav-profile {
        .nav-link {
          height: auto;
          line-height: 1;
          border-top: 0;
          padding: 1.25rem 0;
          .nav-profile-image {
            width: 44px;
            height: 44px;
            img {
              width: 44px;
              height: 44px;
              border-radius: 100%;
            }
          }
          .nav-profile-text {
            margin-left: 1rem;
            .rtl & {
              margin-left: auto;
              margin-right: 1rem;
            }
          }
          .nav-profile-badge {
            font-size: 1.125rem;
            margin-left: auto;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
          }
        }
      }
      &.sidebar-actions {
        margin-top: 1rem;
        .nav-link {
          border-top: 0;
          display: block;
          height: auto;
        }
        &:hover {
          background: initial;
          .nav-link {
            color: initial;
          }
        }
      }
      &.profile {
        padding-right: 0px;
        align-self: center;

        .rtl & {
          padding-right: 10px;
        }
        .profile-desc {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 1rem 1rem 0px;
          line-height: 1.25;
          .profile-name {
            margin: 1rem 0px 3px;
            .rtl & {
              margin-left: 0;
              margin-right: 1rem;
            }
            span {
              font-size: 12px;
              color: $text-muted;
              white-space: nowrap;
            }
            h5 {
              white-space: nowrap;
              color: var(--textColor);
            }
          }
          .profile-pic {
            display: flex;
            flex-direction: column;
            align-items: center;
            .count-indicator {
              position: relative;
              width: 100px;
              height: 100px;
              object-fit: cover;
              .img-md {
                width: 100%;
                height: 100%;
                background-color: #fff;
              }
              .count-symbol,
              .count-number {
                position: absolute;
                border-radius: 100%;
                border: 2px solid $navbar-bg;
              }
              .count-symbol {
                top: 17px;
                right: -3px;
                width: 10px;
                height: 10px;
              }
              .count-number {
                min-width: 14px;
                height: 14px;
                font-size: 0.5rem;
                color: $white;
                bottom: 16px;
                right: -5px;
                line-height: 1;
                text-align: center;
              }
              &:after {
                display: none;
              }
              .count {
                position: absolute;
                left: 76%;
                width: 12px;
                height: 12px;
                color: $white;
                border-radius: 100%;
                text-align: center;
                font-size: 0.625rem;
                line-height: 1.5;
                top: 83px;
                border: 2px solid $border-color;
              }
            }
          }
          .mdi-dots-vertical {
            font-size: 20px;
            color: $text-muted;
          }
          .dropdown-menu {
            padding: 0;
            margin-top: 1.25rem;
            .dropdown-item {
              padding: 11px 13px;
              &.preview-item {
                align-items: center;
                .preview-thumbnail {
                  .preview-icon {
                    width: 30px;
                    height: 30px;
                    i {
                      font-size: 0.875rem;
                    }
                  }
                }
              }
              &:hover {
                color: inherit;
              }
            }
            .dropdown-divider {
              margin: 0;
            }
          }
        }
      }
      &.nav-category {
        padding: 0rem 1.188rem 13px;
        line-height: 0;
        .nav-link {
          color: var(--textColor);
          font-weight: $font-weight-medium;
          font-size: 1rem;
          font-weight: 500;
          height: auto;
          cursor: default;
          justify-content: center;
        }
      }
      .menu-icon {
        margin-right: 0.5rem;
        font-size: 0.8125rem;
        line-height: 1;
        background: rgba($text-muted, 0.2);
        width: 31px;
        height: 31px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        i {
          font-size: 0.875rem;
          margin: 0;
        }
      }
    }
    &.sub-menu {
      margin-bottom: 0;
      margin-top: 0;
      padding: 0 0 0 4.25rem;
      list-style: circle;

      .nav-item {
        padding: 0;
        .nav-link {
          color: var(--textmute-lsidebar);
          padding: $sidebar-submenu-item-padding;
          position: relative;
          font-size: $sidebar-submenu-font-size;
          line-height: 1;
          height: auto;
          border-top: 0;
          &.active {
            color: var(--textColor);
            background: transparent;
          }
          &:hover {
            color: var(--textColor);
            text-decoration: underline;
          }
        }
        &:hover {
          background: transparent;
        }
      }
    }
    &:not(.sub-menu) {
      > .nav-item {
        &:hover {
          &:not(.nav-category):not(.account-dropdown) {
            > .nav-link {
              background: var(--bg-activeleftsidebar-cat);
              color: $sidebar-menu-hover-color;
              .menu-icon {
                i {
                  color: #fff;
                  transition: all ease 0.3s;
                }
              }
            }
          }
        }
      }
    }
    .menu-items {
      .nav-link {
        .menu-icon {
          i {
            // color: #6c7293;
            color: var(--textColor);
            transition: all ease 0.3s;
          }
        }
      }
      &:active {
        .nav-link {
          .menu-icon {
            i {
              color: #fff;
              transition: all ease 0.3s;
            }
          }
        }
      }
      &:hover {
        .nav-link {
          .menu-icon {
            i {
              color: #fff;
              transition: all ease 0.3s;
            }
          }
        }
      }
    }
  }
}

/* style for off-canvas menu*/
@media (min-width: 992px) {
  .sidebar-icon-only
    .sidebar
    .nav
    .nav-item.profile
    .profile-desc
    .profile-pic
    .count-indicator
    .count {
    left: 56% !important;
    top: 25px !important;
  }
}
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    overflow: auto;
    z-index: 5000;
    right: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    &.active {
      right: 0;
    }
  }
}
