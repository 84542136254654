.collapse-button-container {
  position: absolute;
  top: 15px;
  right: 23px;
  z-index: 10;

  .fa-angle-up,
  .fa-angle-down {
    cursor: pointer;

    &:hover {
      color: #999;
    }
  }
}

.badge-warning {
  background-color: #ff9999;
}

.badge-secondary {
  background-color: #0099cc;
}
.badge-danger {
  background-color: #ffcc99;
}
.badge-success {
  background-color: #999966;
}

.box-size {
  height: 30px;
  width: 30px;
  padding-top: 10px;
}
.graph-percent {
  text-align: center;
}
.cyber-risk-graph {
  cursor: pointer;
}

.cyber-risk-link {
  text-decoration: none !important;
}
