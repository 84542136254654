/* @tailwind base;
@tailwind components;
@tailwind utilities; */

.dark-theme {
  --bg-header: #000;
  --bg-color: #000;
  --bg-Modal: #000;
  --textColor: #fff;
  --textColor-SelectedTab: #fff;
  --backdroupColor1: #222;
  --backdroupColor: #222;
  --navVerticleBorder: #2c2e33;
  --bg-leftside: #222;
  --textmute-lsidebar: #ffffffa3;
  --disable-tab-bg: #999;
  --bg-activeleftsidebar: #0f1015;
  --bg-accordian: #0f1015;
  --bg-accordianBorder: #0f1015;
  --cardspeeddail-border: #1d1d1d;
  --bg-card: #000;
  --bg-cardSpeed: #0f1015;
  --bg-cardSpeed1: #0f1015;
  --bg-crad-outer: #222;
  --text-topcardvalue: #ffab00;
  --bg-activeleftsidebar-cat: #000;
  --bg-industrybench-rgt: #222;
  --color-arw-icon: #fff;
  --txt-rgt-heading: #0090e7;
  --txt-rgt-content: #fff;
  --bg-table: #191c24;
  --bg-table-hover: #333;
  --speedDhead: #ddd;
  --bordercolor: #3b3b3b;
  --tableBtmBorder: #2c2e33;
  --brderTopBrd: #2c2e33;
  --bg-dropdown: #191c24;
  --drop-divider: #2c2e33;
  --bg-hover-dropdown: #13151b;
  --bgRiskTable: #111;
  --headRiskTable: #1d1d1d;
  --bgmodalSwitch: #212529;
  --bgTableHover: #222;
  --trackProgress: #191e26;
  --bg-search: #000;
  --scroller-color: #0090e7;
  --border-cardconnector: #1e1c1c;
  --connector-grey: rgb(128, 128, 128);
  --bg-clientLogo: #000;
  --bg-btn: #333;
  --shadow-less: rgba(255, 255, 255, 0.43);
  --shadow-lhover: rgba(255, 255, 255, 0.53);
  --bg-accordianN: #191d24;
  --bg-upload: #191d24;
  --filter-bg: #191d25;
  --date-border: #000;
  --text-bg: #000;
}
.light-theme {
  --bg-header: #fff;
  --bg-color: #f2f2f2;
  --backdroupColor1: #f2f2f2;
  --bg-Modal: #fff;
  --textColor: #000;
  --textColor-SelectedTab: #000;
  --backdroupColor: #fff;
  --navVerticleBorder: #dbdbdb;
  --bg-leftside: #f2f2f2;
  --textmute-lsidebar: #000;
  --disable-tab-bg: #cdcbcb;
  --bg-activeleftsidebar: #9dbde3;
  --bg-accordian: #fff;
  --bg-accordianBorder: #a4a4a4;
  --cardspeeddail-border: #cfcfcf;
  --bg-card: #9dbde3;
  --bg-cardSpeed: #9dbde3;
  --bg-cardSpeed1: #f2f2f2;
  --bg-crad-outer: #fff;
  --text-topcardvalue: #000;
  --bg-activeleftsidebar-cat: #9dbde3;
  --bg-industrybench-rgt: #9dbde3;
  --color-arw-icon: #5691d6;
  --txt-rgt-heading: #00446d;
  --bg-search: #9dbde3;
  --txt-rgt-content: #010101;
  --bg-table: #fff;
  --bg-table-hover: #ecf2f9;
  --speedDhead: #000;
  --bordercolor: #e3e3e3;
  --tableBtmBorder: #e3e3e3;
  --brderTopBrd: #e3e3e3;
  --bg-dropdown: #f2f2f2;
  --drop-divider: #e3e3e3;
  --bg-hover-dropdown: #9dbde3;
  --bgRiskTable: #fff;
  --headRiskTable: #dcdcdc;
  --bgmodalSwitch: #fff;
  --bgTableHover: #d4e0ef;
  --trackProgress: #9dbde3;
  --scroller-color: #9dbde3;
  --border-cardconnector: #dcdcdc;
  --connector-grey: #000;
  --bg-clientLogo: #9dbde3;
  --bg-btn: #535353;
  --shadow-less: rgba(129, 129, 129, 0.43);
  --shadow-lhover: rgba(129, 129, 129, 0.53);
  --bg-accordianN: #fff;
  --bg-upload: #9dbde3;
  --filter-bg: #fff;
  --date-border: #dcdcdc;
  --text-bg: #fff;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/font/proximaNovaRegular.eot");
  src: url("./assets/font/proximaNovaRegular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/proximaNovaRegular.woff2") format("woff2"),
    url("./assets/font/proximaNovaRegular.woff") format("woff"),
    url("./assets/font/proximaNovaRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body,
html,
label,
div,
table th,
table td {
  font-family: "Proxima Nova" !important;
}
a,
a:hover {
  text-decoration: none !important;
}
.modal-content {
  color: var(--bg-Modal) !important;
  border: 1px solid var(--bg-Modal) !important;
}
body {
  padding: 0px !important;
  font-size: 16px !important;
}
.table {
  color: var(--textColor) !important;
}
.table thead th {
  vertical-align: top !important;
  border-bottom: 1px solid var(--tableBtmBorder) !important;
}
.dropdown-menu {
  background-color: var(--bg-dropdown) !important;
  color: var(--textColor) !important;
}
.dropdown-item {
  color: var(--textColor) !important;
}
.comName {
  color: var(--textColor);
}
.dropdown-item:hover,
.dropdown-item:focus {
  background-color: var(--bg-hover-dropdown);
}
.dropdown-divider {
  border-top: 1px solid var(--drop-divider) !important;
}
.table th,
.table td {
  font-size: 16px !important;
}
.table td {
  border-top: 1px solid var(--brderTopBrd) !important;
}
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.noDataMessage {
  text-align: center;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.card {
  background-color: var(--bg-table) !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
table {
  font-size: 16px;
}
.apexcharts-tooltip {
  color: white !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
  border: none !important;
  background-color: #282c34 !important;
}

.apexcharts-tooltip-title {
  background-color: #282c34 !important;
}
.dashboard-heading {
  font-size: 20px;
  margin-bottom: 14px;
  color: var(--textColor);
}
.Modal-heading {
  color: #0090e7;
  font-size: 19px;
}

/* .fortemp{} */
