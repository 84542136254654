.help {
  .container {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background-color: #0090e7;
    width: auto;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    transition: all 0.2s ease-in-out;
    z-index: 5001;
    cursor: pointer;

    &:hover {
      border-radius: 2rem;

      .help-text {
        width: auto;
        display: block;
        margin-left: 0.5rem;
      }
    }

    .help-text {
      width: 0;
      overflow: hidden;
      transition: all 0.2s ease-in-out;
      font-weight: 600;
      display: none;
    }
    &:hover .help-text {
      width: 2.5rem;
    }

    svg {
      width: 1.5rem;
    }
  }

  .body-sub {
    background-color: var(--bg-accordianN);
    display: flex;
    flex-direction: column;
    transition: all 0.2s ease-in-out;
    border-radius: 1rem;

    position: fixed;
    right: 0;
    // top: 0;
    bottom: 2rem;
    // height: 95vh;
    min-height: 25vh;
    width: 20vw;
    z-index: 5000;
    margin: 2rem 0;
    overflow: hidden;
    box-shadow: -23px 12px 15px -3px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 600px) {
      width: 90vw;
    }

    .heading-cont {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #0090e7;
      background-color: #0090e7;
      padding: 0.5rem;
      border-radius: 1rem 1rem 0 0;
      color: #fff;

      p {
        font-size: 19px;
        font-weight: 500;
        margin-bottom: 0 !important;
      }
      svg {
        width: 1.5rem;
        aspect-ratio: 1/1;
        cursor: pointer;
        margin-left: 1rem;
      }
    }

    .messages-cont {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 1rem;
      position: relative;

      a {
        display: flex;
        align-items: center;
        gap: 1rem;
        background: #0090e7b4;
        width: 100%;
        padding: 0.5rem;
        border-radius: 0.5rem;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
        color: white;
        box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.1);

        &:hover {
          scale: 1.05;
        }
        svg {
          width: 1.5rem;
          color: white;
        }
        div {
          font-size: 0.8rem;
          font-weight: 600;
        }
      }

      img {
        width: 80%;
      }

      .query-form {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
        align-items: center;
        justify-content: center;
        background: #191d25;
        transition: all 0.2s ease-in-out;
        transform: translateY(100%);

        .action-cont {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .close-query-box {
            background-color: #0090e7;
            background-color: #0090e7;
            padding: 0.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
              scale: 1.1;
            }
            svg {
              width: 1rem;
              height: 1rem;
              color: white;
            }
          }
        }

        input,
        select {
          background-color: #000;
          border: none;
          width: 100%;
          border-radius: 0.5rem;
          padding: 0.5rem;
          appearance: none;

          option:disabled {
            color: gray;
          }

          option {
            &:hover {
              background-color: red;
            }
          }
        }

        .select-placeholder {
          color: gray;
        }

        .select-selected {
          color: white;
        }

        textarea {
          background-color: #000;
          border: none;
          width: 100%;
          flex-grow: 1;
          border-radius: 0.5rem;
          color: white;
          padding: 0.5rem;
        }

        button {
          border: none;
          background-color: #0090e7;
          background-color: #0090e7;
          color: white;
          font-weight: 600;
          padding: 0.2rem 0.4rem;
          font-size: 0.9rem;
          border-radius: 0.2rem;
          align-self: flex-end;

          &:disabled {
            opacity: 0.8;
            cursor: not-allowed;
          }
        }
      }

      .query-form-active {
        transform: translateY(0);
      }
    }

    @media (width <= 520px) {
      bottom: 35vh;
    }
  }

  .active {
    translate: 0;
  }

  .inactive {
    translate: 120% 0;
    pointer-events: none;
  }

  .send-box-cont {
    background-color: #0090e7;
    background-color: #0090e7;
    padding: 1rem;
    border-radius: 0 0 1rem 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    input {
      background-color: #191d25;
      color: white;
      border: none;
      padding: 0.5rem;
      border-radius: 0.5rem;
      flex-grow: 1;
    }
    svg {
      width: 2rem;
      color: #191d25;
    }
  }

  .popUp {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0090e739;
    .cont {
      min-width: 30vw;
      min-height: 40vh;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
      .heading-cont {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #0090e7;
        background-color: #0090e7;
        padding: 0.5rem;
        border-radius: 1rem 1rem 0 0;
        color: #fff;

        p {
          font-size: 1.2rem;
          font-weight: 700;
          margin-bottom: 0 !important;
        }
        svg {
          width: 1.5rem;
          aspect-ratio: 1/1;
          cursor: pointer;
        }
      }
      .query-form {
        width: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        background-color: #191d25;

        gap: 1rem;
        padding: 1rem;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;

        .action-cont {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .close-query-box {
            background-color: #0090e7;
            background-color: #0090e7;
            padding: 0.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
              scale: 1.1;
            }
            svg {
              width: 1rem;
              height: 1rem;
              color: white;
            }
          }
        }

        input,
        select {
          background-color: #000;
          border: none;
          width: 100%;
          border-radius: 0.5rem;
          padding: 0.5rem;
          appearance: none;

          option:disabled {
            color: gray;
          }

          option {
            &:hover {
              background-color: red;
            }
          }
        }
        .select-placeholder {
          color: gray;
        }

        .select-selected {
          color: white;
        }

        textarea {
          background-color: #000;
          border: none;
          width: 100%;
          flex-grow: 1;
          border-radius: 0.5rem;
          color: white;
          padding: 0.5rem;
          resize: both;
          min-width: 28vw;
          max-width: 80vw;
          max-height: 60vh;
        }

        button {
          border: none;
          background-color: #0090e7;
          background-color: #0090e7;
          color: white;
          font-weight: 600;
          padding: 0.2rem 0.4rem;
          font-size: 0.9rem;
          border-radius: 0.2rem;
          align-self: flex-end;

          &:disabled {
            opacity: 0.8;
            cursor: not-allowed;
          }
        }
      }

      @media (width <= 520px) {
        width: 90%;
      }
    }
  }
}

.global-popup {
  background-color: #000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5002;
}
