/* You can add global styles to this file, and also import other style files */
$scrollBarColor: #8a5e23;

:root {
  font-size: calc(12px + 0.5vmin);
  --admin: #0090e7;
  --operations: #055c9d;
  --ciso: yellow;
}

.print-background {
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
  background-color: var(--bg-color) !important;
  color: var(--textColor) !important;
}

.Toastify__toast-container--top-center {
  transform: none;
}

// global
// scrollbars
/* scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #0090e7 !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.no-scrollbar,
.content-wrapper {
  scroll-behavior: smooth;

  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none; // WebKit-based browsers
  }
}

.yes-scrollbar {
  scroll-behavior: auto;

  scrollbar-width: auto; /* Firefox */
  &::-webkit-scrollbar {
    display: auto; /* WebKit-based browsers */
  }
}

.hide {
  display: none;
}
.overflow-y-scroll {
  overflow-y: scroll;
}

.invisible {
  display: none !important;
}

.italic {
  font-style: italic;
}

.cursor-pointer {
  cursor: pointer;
}

/* === Plugin styles === */

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "./assets/styles/variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "./assets/styles/fonts";
@import "./assets/styles/functions";

/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";

/* === Template mixins === */
@import "./assets/styles/mixins/animation";
@import "./assets/styles/mixins/badges";
@import "./assets/styles/mixins/buttons";
@import "./assets/styles/mixins/misc";
@import "./assets/styles/mixins/color-functions";
@import "./assets/styles/mixins/cards";
@import "./assets/styles/mixins/blockqoute";

/* === Core Styles === */
@import "./assets/styles/typography";
@import "./assets/styles/reset";
@import "./assets/styles/responsive";
@import "./assets/styles/misc";
@import "./assets/styles/utilities";
@import "./assets/styles/demo";
@import "./assets/styles/spinner";
@import "./assets/styles/dashboard";

/* === Components === */

@import "./assets/styles/components/checkbox-radio";
@import "./assets/styles/components/forms";
@import "./assets/styles/components/icons";
@import "./assets/styles/components/tables";
@import "./assets/styles/components/buttons";
@import "./assets/styles/components/breadcrumbs";
@import "./assets/styles/components/badges";
@import "./assets/styles/components/cards";
@import "./assets/styles/components/preview";
@import "./assets/styles/components/lists";
@import "./assets/styles/components/todo-list";
@import "./assets/styles/components/dropdowns";
@import "./assets/styles/components/loaders/loaders";

@import "./assets/styles/components/landing-screens/auth";

/* === Plugin Overrides === */
@import "./assets/styles/components/plugin-overrides/slick-carousel";
@import "./assets/styles/components/plugin-overrides/jquery-jvectormap";

/* === Layout === */
@import "./assets/styles/navbar";
@import "./assets/styles/sidebar";
@import "./assets/styles/footer";
@import "./assets/styles/layout";
@import "~react-toastify/dist/ReactToastify.css";

* {
  scroll-padding-top: 200px;
}

.main-panel {
  // height: 100vh;
  // overflow-y: scroll;
  padding-top: 96px !important;
}
label {
  color: var(--textColor);
}
.navbar-brand-wrapper {
  height: 15vh;
}

.MuiBox-root {
  overflow-y: auto;
}

// @media (max-width: 1024px)
// {
// 	.MuiBox-root, .main-dashboard .notification-content, .insights-container{
// 		height: auto;
// 	}
// 	.content-wrapper{
// 		overflow-y: scroll;
// 	}

// }
// .bs-tab .MuiBox-root {
//   height: 100%;
// }
@media (max-width: 991.98px) {
  .popover,
  .dropdown-menu {
    top: calc(15vh + 11rem) !important;
  }
}

@media (max-width: 767px) {
  .main-panel {
    padding-top: 15vh !important;
    overflow-y: scroll;
  }

  .notification-conten {
    height: 100% !important;
    padding-top: 15vh !important;
    overflow-y: scroll;
  }
}

.golden {
  color: #8a5e23;
}

.golden-shine {
  color: var(--color-arw-icon);
  transition: all ease 0.3s;
}
.golden-shine :hover {
  color: #0090e7;
  transition: all ease 0.3s;
}
nav.sidebar {
  height: 100vh;
  overflow-y: auto;
  box-shadow: 0px 17px 28px -17px rgb(0, 0, 0);
}

.configureModel .inner .inner-most {
  height: 60vh;
}

.disable-item {
  pointer-events: none;
  cursor: not-allowed;
}

.disable-nav-item {
  list-style-type: "" !important;
}

.disable-btn {
  opacity: 0.5;
}

// switch global css

#check-box .toggle {
  position: relative;
  box-sizing: border-box;
}

#check-box .toggle input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

#check-box .toggle input[type="checkbox"]:checked + label:before {
  background: #97f23b;
}

#check-box .toggle input[type="checkbox"]:checked + label:after {
  left: 25px;
  background: #fff;
}

#check-box .toggle label {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

#check-box .toggle label:before {
  content: "";
  width: 45px;
  height: 23px;
  position: relative;
  display: inline-block;
  background: #474748;
  border-radius: 46px;
  border: 1px solid #000;
  box-sizing: border-box;
  transition: 0.2s ease-in;
}

#check-box .toggle label:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  left: 4px;
  top: 3px;
  z-index: 2;
  background: #fff;
  box-sizing: border-box;
  transition: 0.2s ease-in;
}

.opacity-0 {
  opacity: 0;
}

.glob-popUp {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 5000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0090e739;
  overflow-y: auto;
}

.pointer {
  cursor: pointer;
}

.gold-text {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0.25, #68461d),
    color-stop(0.55, #f5ea9f),
    color-stop(0.75, #68461d)
  );
  color: transparent;
  background-clip: text;
}

.btn-custom-color {
  background: #0090e7;
}
.bg-darkblack-w {
  background-color: var(--bg-cardSpeed1);
}
.apexcharts-yaxis-label tspan,
.apexcharts-xaxis-label tspan,
.apexcharts-text {
  fill: var(--textColor) !important;
}
.bg-darkblack {
  background-color: var(--bg-cardSpeed);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6);
}
.bg-darkblack-tab {
  background-color: var(--bg-color);
}
.bg-inherit {
  background-color: inherit;
}

.rounded-md {
  border-radius: 2rem;
}
.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.marginned-cont {
  margin: 1rem;
}

.closeCustomModal {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.header-heading {
  color: #0090e7 !important;
}

.exclamation-mark-color {
  color: #fc424a !important;
}
.table-hover tbody tr:hover {
  color: #212529;
  background-color: #c2d5eb !important;
}
