.chatbot_container {
  height: 90vh;
  width: 25vw;
  z-index: 20000;
  position: fixed;
  right: 20px;
  bottom: 40px;
  border-radius: 20px;
  background-color: #222222;

  .mobile_container {
    position: absolute;
    bottom: 0;
    height: 100%;
    background-color: rgb(17, 17, 17);
    width: 100%;
    border-radius: 20px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding-top: 10%;

    .screen1 {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      width: 100%;

      .prompt {
        background-color: rgb(68, 68, 68);
        cursor: pointer;
        border-radius: 8px;
        padding: 5px 10px;
        width: fit-content;
        // width: 50%;

        p {
          color: white;
          margin-bottom: 0;
        }
      }

      .historyArea {
        flex-grow: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        ::-webkit-scrollbar {
          display: none;
        }

        .promptsArea {
          display: flex;
          flex-direction: row;
          // align-items: center;
          overflow-x: auto;
          white-space: nowrap;
          gap: 10px;

          .prompt {
            // width: 80px;
            width: max(40%, 200px);
            text-wrap: wrap;
            height: 6ch;
            display: flex;
            align-items: center;

            @media (width < 500px) {
              min-width: 50%;
            }

            @media (width > 500px) {
              min-width: fit-content;
            }
          }
        }
      }
      .favouriteArea {
        flex-grow: 1;
        width: 100%;
        display: flex;
        flex-direction: column;

        ::-webkit-scrollbar {
          display: none;
        }
        .promptsArea {
          display: flex;
          flex-direction: row;
          align-items: center;
          overflow-x: auto;
          white-space: nowrap;
          gap: 10px;

          .prompt {
            // width: 80px;
            position: relative;
            width: max(40%, 200px);
            text-wrap: wrap;
            height: 6ch;
            display: flex;
            align-items: center;

            @media (width < 500px) {
              min-width: 50%;
            }

            @media (width > 500px) {
              min-width: fit-content;
            }
          }
        }

        h4 {
          text-align: center;
        }
      }
    }

    .lottie {
      scale: 0.8;
      opacity: 0.5;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 7%;
      padding: 5px 10px;
      width: 100%;
      position: absolute;
      top: 0;

      span {
        cursor: pointer;
        color: white;
        padding: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        text-align: center;
      }

      .expand {
        height: 10px;
        @media (width < 500px) {
          display: none;
        }
      }
    }

    .message_container {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      .messages {
        flex: 1;
        overflow-y: auto;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        // justify-content: flex-end;

        .user_message,
        .backend_message {
          display: flex;
          align-items: flex-end;
          max-width: 70%;
          word-wrap: break-word;
          margin-bottom: 30px;

          .insignia {
            background-color: #007bff;
            color: white;
            margin-left: 10px;
            flex-shrink: 0;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 16px;
          }
        }

        .user_message {
          justify-content: flex-end;
          align-self: flex-end;
          // display: flex;
          // align-items: center;

          p {
            background-color: rgb(47, 48, 48);
            border-radius: 10px;
            padding: 10px 15px;
            margin-bottom: 0;
          }
        }

        .backend_message {
          justify-content: flex-start;
          align-self: flex-start;
          // display: flex;
          // align-items: center;
          .insignia {
            background-color: #ffc107;
            // color: black;
            margin-right: 10px;
          }
          img {
            width: 10px;
          }

          p {
            background-color: rgb(47, 48, 48);
            border-radius: 10px;
            padding: 10px 15px;
            margin-bottom: 0;
          }
        }
      }

      ::-webkit-scrollbar {
        display: none;
      }
    }

    .bottom_container {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      padding: 0 10px 10px 10px;
      justify-content: space-evenly;
      background-color: #000;
    }

    .file_upload_container {
      label {
        cursor: pointer; // Make the label behave like a clickable button
        margin-bottom: 0px;
        .attachment_icon {
          width: 40px; // Example size, adjust as needed
          height: 40px;
        }

        .file_input {
          display: none; // Hide the actual file input element
        }
      }
    }

    .input_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      border-radius: 50px;
      background-color: rgb(0, 0, 0);
      padding: 4px;
      gap: 1rem;
      border: 1px solid #0090e7;

      .input {
        margin-left: 10px;
        background-color: transparent;
        border: none;
        outline: none;
        width: 90%;
        color: #fff;
        &::placeholder {
          color: #fdf9f9;
        }
      }

      .sendbtn_container {
        background-color: #0090e7;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        cursor: pointer;
      }
    }
  }

  .popular_prompts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-grow: 1;

    .prompt {
      background-color: rgb(68, 68, 68);
      cursor: pointer;
      border-radius: 8px;
      padding: 5px 10px;

      p {
        color: white;
        margin-bottom: 0;
      }
    }
  }

  .new_chat {
    background-color: rgb(229, 229, 229);
    color: black;
    cursor: pointer;
    border-radius: 8px;
    width: 100%;
    padding: 10px;
    text-align: center;
    margin-top: auto;
  }

  @media (width <= 500px) {
    width: 100dvw;
    height: 99dvh;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.loading_gif {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  animation: fadeInOut 2s linear;

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.maximized {
  width: 70vw;
  right: 50%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20000;
  border-radius: 20px;
  background-color: #222222;
}
