// User Pages

.app-logo {
  height: $logo-height;
  width: $logo-width;
  // background: url("~assets/utils/images/logo-inverse.png");
}

.app-logo-inverse {
  height: $logo-height;
  width: $logo-width;
  // background: url('~assets/utils/images/logo.png');
}

.app-login-box {
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: 0.6;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}

.loginHeader {
  background: #000;
  color: #fff;
  padding: 10px;
  height: 25vh;
  margin: 0px;
}
.loginText {
  color: goldenrod;
  font-size: 50px;
}
.loginTextContainer {
  display: flex;
  align-items: center;
}
.loginLogo {
  width: 32%;
}
.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72vh;
  background-color: #1d1d1e;
}

.otpContainer {
  padding: 40px 80px;
}
.labelOtpMsg {
  color: $white;
  font-size: 1.2rem;
  font-style: italic;
}
.mt-top {
  margin-top: 2rem !important;
}
.labelOtp {
  color: $white;
  font-size: 1.2rem;
}
.labelResendOtp {
  color: $teal;
  font-style: italic;
  text-decoration: underline;
  font-size: 1rem;
}

.loginSubmitBtn {
  padding: 10px 30px;
  background-color: rgb(250, 189, 36);
  color: $gray-900;
  font-size: 1.2rem;
  font-weight: 700;
  border-radius: 10px;
}

.loginSubmitBtn:hover {
  background-color: rgb(250, 189, 36);
  color: $gray-900;
}
.loginSubmitBtn:focus {
  background-color: rgb(250, 189, 36);
  color: $gray-900;
}
.loginInput {
  padding: 25px;
  background-color: #1d1d1e;
  color: $white !important;
  width: 350px;
  border: 1px solid white;
}
.loginInput:focus {
  background-color: #1d1d1e;
  outline: none;
}

.mt-top-1 {
  margin-top: 1rem !important;
}
.alignLoginSubmitBtn {
  text-align: center;
}
.labelForgotPassword {
  margin-left: 6px;
}
.borderRadiusHeader {
  border-top: 4px solid goldenrod;
  border-right: 7px solid goldenrod;
}
.borderRadiusBody {
  border-top: 2px solid #fff;
  border-right: 7px solid goldenrod;
}
.loginInputRoles {
  background: transparent !important;
  padding: 12px;
  color: $white !important;
  width: 350px;
  border: 1px solid white;
  height: 53px;
}

.loginInputRoles > option {
  background-color: black !important;
}
