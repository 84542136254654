// Header Base

.app-header {
  height: $app-header-height;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 10;
  transition: all 0.2s;

  &.header-shadow {
    box-shadow: $box-shadow-default;
  }

  .app-header__content {
    display: flex;
    align-items: center;
    align-content: center;
    flex: 1;
    padding: 0 $layout-spacer-x;
    height: $app-header-height;

    .app-header-left {
      display: flex;
      align-items: center;
    }

    .app-header-right {
      align-items: center;
      display: flex;
      margin-left: auto;
    }
  }

  .header-user-info {
    & > .widget-heading,
    & > .widget-subheading {
      white-space: nowrap;
    }

    & > .widget-subheading {
      font-size: $font-size-xs;
    }
  }
}

.app-header__logo {
  padding: 0 $layout-spacer-x;
  height: $app-header-height;
  width: $app-sidebar-width;
  display: flex;
  align-items: center;
  transition: width 0.2s;

  .logo-src {
    /*height: $logo-height;
    width: $logo-width;*/
    // background: url('~assets/utils/images/logo-inverse.png');
    //Commented to replace img with text
    height: 60px;
    width: 190px;
    color: goldenrod;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > p {
      margin-bottom: 0;
      font-size: 19px;
      color: rgb(249, 195, 18);
      line-height: 1p;
      text-shadow: 0 1px 0 goldenrod;
    }

    & > span {
      font-size: 8px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 500;
    }
  }
}

.productName {
  font-size: x-large;
  margin-top: 10px;
  color: #dca218;
}
.app-header__menu,
.app-header__mobile-menu {
  display: none;
  // padding: 0 $layout-spacer-x;
  padding: 0 $layout-spacer-xx;
  height: $app-header-height;
  align-items: center;
}

.header-calender-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__datePicker {
    width: 54%;
    outline: none;
    border: none;
    background: transparent;
    position: relative;
    top: 0.3rem;
  }
  &__calenderIcon {
    position: relative;
    right: 6rem;
    top: 0.1rem;
  }
  &__duartion {
    position: relative;
    right: 2rem;
    top: 0.1;
    background: transparent;
    border: none;
    width: 9rem;
    & > option {
      color: black;
    }
    &__control {
      background: transparent !important;
      border: none !important;
      outline: none !important;
    }
    &__value-container > div {
      color: #000;
    }
    &__dropdown-indicator > svg {
      color: #000;
    }
  }
}

// Header Modifiers

@import 'modifiers/fixed-header';
@import 'modifiers/header-dots';
@import 'modifiers/header-megamenu';
@import 'modifiers/header-buttons';
//@import "modifiers/header-horizontal";

// Header Themes

@import 'themes/header-light';
@import 'themes/header-dark';
//@import "themes/header-inverted";
