.datapointColumn {
  & label {
    font-weight: 700;
    color: #545cd8;
  }
}

.margin-top-1 {
  margin-top: 1rem;
}

.padding-0 {
  padding: 0px;
}
.padding-right-0 {
  padding-right: 0px;
}

.dataCard {
  background-color: #e9e6e6;
  margin-bottom: 0.75rem;

  &__overflow {
    overflow-wrap: anywhere;
  }
}

.dataPointLabel {
  font-size: 1.2em;
  display: flex;
  justify-content: space-between;
  & > input {
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
  }

  & > .pe-7s-check {
    position: relative;
    width: 40px;
    height: 40px;
    background: #091921;
    line-height: 40px;
    text-align: center;
    margin: 0 4px;
    color: #6f6f6f;
    font-size: 1.2em;
    font-weight: bold;
    border-radius: 50%;
    box-shadow: -1px -1px 3px rgba(255, 255, 255, 0.1),
      2px 2px 6px rgba(0, 0, 0, 0.8);
  }
  & > .pe-7s-check:hover {
    box-shadow: -1px -1px 3px rgba(255, 255, 255, 0.1),
    2px 2px 6px rgba(0, 0, 0, 0.8),
    inset -2px -2px 10px rgba(255, 255, 255, 0.05),
    inset 2px 2px 10px rgba(0, 0, 0, 0.5);
  }
  input:checked ~ .pe-7s-check {
    color: #00fff1;
    box-shadow: inset -2px -2px 10px rgba(255, 255, 255, 0.05),
      inset 2px 2px 10px rgba(0, 0, 0, 1);
    text-shadow: 0 0 5px #00fff1, 0 0 20px #00fff1;
  }
}
