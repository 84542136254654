.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  img {
    width: 40%;
    height: auto;
    margin-bottom: 1rem;
  }
  p {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 130%;
  }
  .error-msg {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-weight: bold;
    gap: 1rem;

    h4 {
      font-size: 2rem;
      font-weight: 600;
    }
  }
  .btn {
    width: fit-content;
    border: none;
    padding: 0.5rem 0.8rem;
    background: rgb(255, 171, 0);
    color: #fff;
    letter-spacing: 1px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
  }
}
