.switch-role-modal {
  // min-width: 340px;
  height: auto;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: #0090e7 !important;
}
.MuiTypography-h6 {
  font-family: "Proxima Nova" !important;
}
.btnDone,
.btnCancel {
  font-size: 15px;
  padding: 6px 16px !important;
  text-transform: capitalize !important;
}
.btnDone:hover,
.btnCancel:hover {
  transition: all ease 0.3s;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
}

// .container {
//   justify-content: flex-end;

//   @media screen and (max-width: 500px) {
//     justify-content: center;
//   }
// }

.dialog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.dialogActions {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.btnCancel,
.btnDone {
  margin-right: 15px;
}

@media (max-width: 600px) {
  .dialogContainer {
    padding: 8px;
  }

  .btnCancel,
  .btnDone {
    margin-right: 8px;
  }
}

.dialog-paper {
  margin: none;
  margin: 0 auto;
  width: 100%;

  /* For screens larger than 600px */
  @media (min-width: 600px) and (max-width: 800px) {
    width: 80% !important;
  }

  /* For screens larger than 800px and up to 1100px */
  @media (min-width: 801px) and (max-width: 1200px) {
    width: 70% !important;
  }

  /* For screens larger than 1100px */
  @media (min-width: 1201px) {
    width: 70% !important;
  }
}
.dialog-paper .MuiDialog-paper {
  margin: 0;
}
